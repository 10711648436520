* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #cef;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes rotateAnimation {
    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

@keyframes errorAnimation {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(10deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #414141;
}

h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 21px;
}

.home-page-list {
    list-style: none;
}

.closeButton {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: #000000;
}

.home-page-list li {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    display: flex;
    align-items: center;
}

.home-page-list li svg {
    margin-right: 10px;
}

.home-page-list li:not(:last-child) {
    margin-bottom: 10px;
}

.main-button {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 18px;
}

.beauty-list {
    list-style: none;
}

.beauty-list li:not(:last-child) {
    margin-bottom: 15px;
}

.beauty-list li {
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.beauty-list li span {
    font-family: "Open Sans", sans-serif;
    color: white;
    background-color: #E9392C;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.indexPageBusImage {
    max-width: 60%;
    height: auto;
}

.videoPlaceholderImage {
    margin-bottom: 30px;
}

.ol-list {
    padding-left: 20px;
}

.ol-list li {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 1.5em;
}

.ol-list li:not(:last-child) {
    margin-bottom: 10px;
}

.make-photo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
}

.dl-placeholder {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 350px;
    height: 200px;
    border: 3px solid white;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 2000px rgb(0 0 0 / 60%);
}

.image-result-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.image-result-wrapper-inner {
    background-color: #E1E1E1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
}

.image-result-wrapper-inner img {
    max-width: 100%;
    height: auto;
}

.documents-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.document-item {
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    color: #E9392C;
    margin-top: 10px;
}

.document-item p {
    text-decoration: underline;
    margin-left: 10px;
}

.document-item-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    background-color: #E9392C;
}

.document-item-success .document-item-inner {
    background-color: #7EE96D;
}

.document-item-success {
    color: #7EE96D;
}

.app-language-select {
    height: 30px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #B6B6B6;
}

.main-button-register {
    background-color: #E9392C !important;
    height: 50px;
    width: 318px;
    border-radius: 30px !important;
    color: #ffffff !important;
    font-family: "Open Sans", sans-serif !important;
    font-size: 16px !important;
    text-transform: none !important;
    margin-bottom: 20px !important;
}

.borderError {
    border-radius: 4px;
    border: 1px solid red;
}

.main-button-register:disabled {
    background-color: #D7D7D7 !important;
}

.main-button-register[data-disabled="true"] {
    background-color: #D7D7D7 !important;
    box-shadow: none;
}

.outlined-button {
    background-color: #fff !important;
    border-radius: 20px !important;
    color: black !important;
    border: 1px solid black !important;
    text-decoration: underline !important;
    font-weight: 600 !important;
}

.indexPageTitle {
    font-size: 28px;
    margin-top: 0;
}

.category-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    margin-left: 0 !important;
    border: 1px solid rgba(0, 0, 0, 0.23);
    height: 56px;
}

.category-checkbox > span:first-child {
    color: #E9392C !important;
    width: 30px !important;
}

.containerAlignBottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 85vh;
}

.indexPageCenterContainer {
    display: flex;
    flex-direction: column;
}

.confirmConsentButton {
    background-color: #E9392C !important;
    border-radius: 30px !important;
    width: 150px;
    height: 40px;
    font-family: "Open Sans", sans-serif;
    color: #ffffff !important;
    font-size: 16px;
}

.confirmConsentButton:disabled {
    background-color: #C5C5C5 !important;
}

.cancelButtonText {
    color: #222222 !important;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.nextButtonText {
    color: white !important;
    background-color: #E9392C !important;
    border-radius: 30px !important;

}

.nextButtonTextWidth {
    min-width: 200px !important;
}

.nextButtonTextWidth:disabled {
    background-color: gray !important;
}

.checkboxKav2go svg {
    color: #E9392C;
}

.secondH1Title {
    font-size: 24px;
    margin-top: 0;
}

.popup-title {
    font-weight: bold !important;
    font-family: "Montserrat", sans-serif !important;
    font-size: 18px !important;
    text-align: center;
}

.popup-content-wrapper {

}

.popup-content-wrapper ul {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
}

.popup-content-wrapper ul li {
    font-family: "Montserrat", sans-serif !important;
    padding: 5px 0;
    font-weight: 500;
    color: #000000 !important;
}

.make-photo-title {
    position: fixed;
    top: 20px;
    left: 0;
    text-align: center;
    z-index: 10;
    color: white;
    font-family: "Montserrat", sans-serif !important;
    font-weight: bold;
    width: 100%;
    padding: 0 10px;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
    line-height: 1.5em;
}

.make-photo-description {
    position: fixed;
    bottom: 20px;
    left: 0;
    text-align: center;
    line-height: 1.5em;
    z-index: 10;
    color: white;
    font-family: "Montserrat", sans-serif !important;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    padding: 0 10px;
    padding-top: 10px;
    border-top: 1px solid white;
}

.document-image {
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 200px;
    height: auto;
}

.warning-image {
    max-width: 50px;
}

.document-video {
    max-width: 100%;
    height: auto;
    margin-bottom: 30px;
}

.make-photo-btn {
    background-color: #E9392C !important;
    width: 90px !important;
    border-radius: 30px !important;
    border: none !important;
    position: absolute !important;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
}

.make-photo-btn svg {
    color: white;
}

.small-subtitle {
    text-align: center;
    font-size: 16px;
    color: black;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 10px;
}

.kav2go-checkbox-style {
    color: #E9392C !important;
}

.password-list {
    list-style: none;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 1.5em;
}

.password-list img {
    margin-right: 10px;
}

.contact-item {
    display: flex;
    align-items: flex-start;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
}

.contact-item p {
    color: black;
}

.contact-item p a {
    display: block;
    color: #E9392C;
}

.done-icon img {
    max-width: 250px;
    height: auto;
}

.done-page-title {
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    color: #414141;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.done-page-text {
    font-family: "Open Sans", sans-serif;
    color: #E9392C;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 20px;
}

.done-icon-wrapper {
    margin-top: 15px !important;
}

.choose-document-item {
    border: 1px solid #F56E64;
    border-radius: 6px;
    padding: 15px 25px;
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
}

.choose-document-item img {
    max-width: 150px;
    height: auto;
}

.choose-document-item p {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
}

.zipCode input {
    text-transform: uppercase !important;
}

.secondH1Title {
    font-size: 21px;
}

.selfie-make-photo-btn {
    position: absolute;
}

.make-photo-selfie-wrapper {
    position: relative;
}

.form-wrapper-title {
    text-align: center;
}

.simple-button {
    background-color: #fff !important;
    color: black !important;
    box-shadow: none !important;
    font-weight: 600 !important;
    font-size: 18px !important;
}

.popup-action-button-margin {
    margin-bottom: 10px !important;
}

.navbar-burger-btn {
    width: 47px;
    height: 32px;
    border: 1px solid #E9392C;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    cursor: pointer;
}

.navbar-burger-btn span {
    display: block;
    background-color: #E9392C;
    width: 20px;
    height: 1px;
    margin: 0 auto;
    position: relative;
}

.navbar-burger-btn span:nth-child(1) {
    top: -2px;
}

.navbar-burger-btn span:nth-child(3) {
    top: 2px;
}

.navbar-account-balance {
    min-width: 100px;
    padding: 0 10px;
    height: 37px;
    border: 1px solid #E9392C;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.navbar-account-balance span {
    display: flex;
    align-items: center;
    color: #E9392C;
    width: 100%;
    justify-content: center;
}

.app-navbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.dashboard-person-name {
    color: #E9392C;
    text-align: center;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-reservations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 140px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: #fff;
}

.dashboard-reservations p {
    color: #E9392C;
    font-size: 18px;
}

.dashboard-reservations-active {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 160px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background-color: #fff;
    font-family: "Open Sans", sans-serif;
}

.app-page-wrapper {
    background-color: #FDFDFD;
    padding: 10px;
}

.dashboard-reservations-line {
    background-color: #F5F5F5;
    width: 90%;
    height: 1px;
    display: block;
    margin: 10px 0;
}

.future-reservations-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.future-reservations-header-title {
    font-weight: bold;
    color: #666666;
    cursor: pointer;
}

.future-reservations-content-no-item {
    text-align: center;
    margin-top: 20px;
}

.app-footer {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    padding: 10px !important;
}

@media (min-width: 1000px) {
    .app-footer {
        left: 50%;
        transform: translateX(-50%);
    }

    .dashboard-reservations-active-id {
        font-size: 16px !important;
    }
}

.start-reservation-button {
    width: 225px;
    height: 45px;
    background-color: #E9392C;
    color: white;
    font-size: 18px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
}

.app-footer-btn {
    width: 120px;
    height: 37px;
    border: 1px solid #E9392C;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #E9392C;
    cursor: pointer;
}

.app-sidebar {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 2;
    transition: .3s all;
    transform: translateX(-100%);
}

.app-sidebar-active {
    transition: .3s all;
    transform: translateX(0);
}

.app-sidebar-menu {
    background-color: #FDFDFD;
    width: 80%;
    height: 100%;
    padding: 10px;
}

.app-sidebar-close {
    color: white;
    font-size: 100px;
    position: fixed;
    font-weight: 100;
    right: 10px;
    top: -30px;
    cursor: pointer;
}

.navbar-account-balance-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 43px;
    padding: 0 10px;
    cursor: pointer;
}

.navbar-account-balance-menu span {
    color: #E9392C;
}

.navbar-account-balance-menu-dots span {
    display: block;
    background-color: #E9392C;
    position: relative;
    height: 2px;
    width: 2px;
    border-radius: 50%;
}

.navbar-account-balance-menu-dots span:nth-child(1) {
    top: -2px;
}

.navbar-account-balance-menu-dots span:nth-child(3) {
    top: 2px;
}

.app-sidebar-link a, .sidebar-language, .sidebar-menu-item {
    min-width: 210px;
    max-width: 260px;
    height: 37px;
    display: flex;
    align-items: center;
    border: 1px solid #E9392C;
    border-radius: 20px;
    padding-left: 16px;
    color: #E9392C;
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 15px;
    cursor: pointer;
}

.sidebar-logout {
    width: 210px !important;
    max-width: 210px !important;
}

@media (min-width: 1000px) {
    .app-sidebar-link a, .sidebar-language, .sidebar-menu-item {
        width: 300px
    }
    .app-footer-btn {
        width: 150px;
        height: 48px;
        font-size: 16px;
    }
    .sidebar-logout {
        width: 260px !important;
        max-width: 260px !important;
    }
}

.app-sidebar-link img, .sidebar-menu-item img {
    margin-right: 15px;
}

.sidebar-language {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.sidebar-active-language {
    font-weight: bold;
    font-size: 16px;
    margin-right: 15px;
}

.sidebar-language-wrapper {
    position: relative;
    width: 210px;
}

.sidebar-sublanguages {
    position: absolute;
    right: -90px;
    top: 3px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-sublanguages span {
    color: #E9392C;
    font-size: 14px;
    padding: 10px;
    display: block;
    cursor: pointer;
}

.sidebar-sublanguages span:first-child {
    border-right: 1px solid #E9392C;
}

@media (min-width: 1000px) {
    .sidebar-sublanguages {
        right: -180px;
    }
    .active-manage-image {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .active-manage-image img {
        max-width: 200px !important;
    }

    .active-manage-image {
        position: relative;
    }

    .active-manage-fixed {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.navbar-account-balance-submenu {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    min-width: 160px;
    height: 68px;
    position: absolute;
    right: 0;
    top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.navbar-account-balance-submenu a {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #666666;
    text-decoration: none;
}

.navbar-account-balance-submenu a:not(:last-child) {
    margin-bottom: 5px;
}

.navbar-account-balance-submenu a span {
    margin-top: 2px;
}

.navbar-account-balance-submenu a img {
    margin-right: 5px;
}

@media (min-width: 1000px) {
    .app-sidebar-menu {
        width: 30%;
    }
    .sidebar-footer-btn {
        width: 27% !important;
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #3458eb;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

.topUpAnimated {
    animation: topUpAnimation 1s;
}

@keyframes topUpAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes blinkingAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }

    5% {
        transform: scale(1.3);
    }

    10% {
        transform: scale(1);
    }

    15% {
        transform: scale(1.3);
    }

    20% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.3);
    }

    30% {
        transform: scale(1);
    }

    35% {
        transform: scale(1.3);
    }

    40% {
        transform: scale(1);
    }

    45% {
        transform: scale(1.3);
    }

    50% {
        transform: scale(1);
    }

    55% {
        transform: scale(1.3);
    }

    60% {
        transform: scale(1);
    }

    65% {
        transform: scale(1.3);
    }

    70% {
        transform: scale(1);
    }

    75% {
        transform: scale(1.3);
    }

    80% {
        transform: scale(1);
    }

    85% {
        transform: scale(1.3);
    }

    90% {
        transform: scale(1);
    }

    95% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

.account-deactivated-icon {
    animation: alertInformation 1s infinite;
}

@keyframes alertInformation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.alertInformationSmallAnimation {
    animation: alertInformationSmall 1s;
}

@keyframes alertInformationSmall {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@media (min-width: 1000px) {
    .manage-btn-group {
        margin-top: 32px !important;
    }
    .manage-btn-group-down {
        margin-bottom: 16px !important;
    }
    .navbar-burger-btn {
        width: 60px !important;
        height: 40px !important;
    }
}

@media (min-width: 1200px) {
    .dl-placeholder {
        width: 700px;
        height: 420px;
    }
    .make-photo-description {
        border-top: none;
    }
}

@media (min-width: 900px) {
    .hide-desktop {
        display: none !important;
    }

    .selfie-make-photo {
        max-width: 100% !important;
        width: auto !important;
        height: auto !important;
    }

    .dl-placeholder {
        width: 700px;
        height: 420px;
    }

    .homeLeft img {
        max-width: 100%;
        height: auto;
    }

    video {
        height: auto;
        width: 100%;
    }

    .image-result-wrapper-inner {
        width: 100%;
    }

    .homeLeft {
        justify-content: center;
    }

    .homeRight {
        justify-content: center !important;
    }

    .indexPageTitle {
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .main-button-register-home{
        margin-top: 40px !important;
    }

    .document-video {
        max-width: 500px;
        height: auto;
    }

    .document-image {
        max-width: 100%;
    }

    .take-photo-btn {
        margin-top: 40px !important;
    }

    .desktop-take-photo-center {
        display: flex;
        justify-content: center;
        align-self: center;
    }
}

@media (max-width: 899px) {
    .hide-mobile {
        display: none !important;
    }
}

@media (max-width: 330px) {
    .indexPageTitle {
        font-size: 24px;
    }
    .main-button-register {
        width: 260px;
    }
    h2 {
        font-size: 18px;
    }
    .dl-placeholder {
        width: 300px;
        height: 180px;
    }
    .secondH1Title {
        font-size: 18px;
    }
    .document-image {
        max-width: 150px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .ol-list li {
        font-size: 14px;
    }
    .category-checkbox > span {
        font-size: 14px !important;
    }
    .choose-document-item img {
        max-width: 100px;
    }
    .warning-image {
        max-width: 40px;
        height: auto;
    }
}